export const stateIndexByPubKey = {
  "macipk.cec46c69322f7a14460721a95ea2a1074a6ed2e07d62f260d59c254404b3dd9f": "1",
  "macipk.178837afed6d11544c13856b358df72bc328101e237a3064b60dfb1981a4f399": "2",
  "macipk.cb245474f9e708c33275892580404a3a89b4c121fe200cd5e9a408c4f202da8d": "3",
  "macipk.a92e1976c9b510834ab32e0c4666c432234095479ab25c5b115252e580b2d60f": "4",
  "macipk.98f82fb9106219ac6695847995c8e369ff1a9303c974b52ba5ba8876fd5f05ab": "5",
  "macipk.9ec51b79f25f58a04ff8588bc5aa16775e174acbd3a97bb3cc44977437c759a3": "6",
  "macipk.608b7fb9b654adeb0d9ac7c7503d3dbb88c2cf55ba0e12eaf17bea471fbac112": "7",
  "macipk.e601bd2653e39b086715be510c019324d5dbffff454e99b301e40dc186c54706": "8",
  "macipk.98e8043cd95541af52a7f1a2e58d707bfe6f9b85295800f68bb1f5e9d84c1d92": "9",
  "macipk.6463e04d651dfbe5bb75de37dce31ea18e29370f5f9d9623194609c1c79c57a8": "10",
  "macipk.a9eedeb4387f86d87ad26bc6425b22a9c30f222e5af2d25584138a3484dca8a6": "11",
  "macipk.11e97fd9212dd17263b5796658e92852a62787d777566f18856ac430d14f1a8f": "12",
  "macipk.08c1a9c5e04e10695996e16ee214f53152f807a1ee927cb7465168a28a9124a6": "13",
  "macipk.aa8e7a7b2a7a4a693dbbc85cc3eaf19f71d20797ce9404d6ed3bca51bd446d22": "14",
  "macipk.ae8c4de1c4130c5768892bf72b92837d2906b5429a78e4d1f000076f53bc1d85": "15",
  "macipk.39dee3468e7325bc2d6508bb6e2efc0b30cfbb538f11852c5dc17b468a658d20": "16",
  "macipk.64e29bd0f7006991240ffa167ef54c08a2a14a977dd84dd8b6e0287d44bb3030": "17",
  "macipk.268768f1193ffb4d0d49c6d63d460b260c8fdb82431496f3264eb1eba0199c01": "18",
  "macipk.992116df504a26660a12dfca4470e52f895d8524c1ad6d7d8bdcbf745008e38a": "19",
  "macipk.5b6aaa50cc3c3bf87a5d6c101acbe604e567f0c55d779acff83adf612c45e9aa": "20",
  "macipk.7db9bd9be9cc8ca33f51af816619547ef20529308845fad323ed165dfaa45e80": "21",
  "macipk.4c14d1aa3069cff39108f276a3f946462fd19cd8eb862c0ebffea579a19d4010": "22",
  "macipk.3c7121a35cb4145a301d55bc119629c9a014c0f389743907242b593373c25a80": "23",
  "macipk.98fa37901b05d52bf1e70cd5e88e46d658f2ee55a33b646dc9ab0bf925858802": "24",
  "macipk.02a79e1ef1d46c6b4433179669844431f4e9d8e9242259c6f7fc0fc413e00ba0": "25",
  "macipk.a7e7b20b5975ea6243ea59d7ca06e0ac8da638919558c17af757d8d2986deca8": "26",
  "macipk.c6ce85fcf6dd015658f75fce7ba7cecdf6b1665f0e39a0b590f60f21d72270a4": "27",
  "macipk.e1c1f3c4b47c3e1ad541e4acabc2696b1deca56038598ddd9f5352f06516cc02": "28",
  "macipk.18983efbee3a58c2d22dcbe0738ba5debde02b642fa198820a6bd747aeee808f": "29",
  "macipk.9c71217712d108cc8872350eb344e541df2d5012d6a9793a4f2a6536d2a8cc12": "30",
  "macipk.fe1b950787ecc1324511ec700a83d1a2ecabfeee2e5602522ccfa4f45c0c4524": "31",
  "macipk.0f8b71fe736363a66a4becc481ae90180b7a06a5223a39ece64e24caefbba12c": "32",
  "macipk.4995f034132a7a8198ec7a15e08dd2b9a83a096f5b7c0a516554581b97d2e416": "33",
  "macipk.67cff1cf8ec96855972db816b312233863d1b001ae65fe8b2cb13c052dfed583": "34",
  "macipk.36a698bac09410ff0ece2dba8567772c1ae963d292d2a19a2709a3e735efc51d": "35",
  "macipk.a88d94c14329187393a3abb5d7ad13fcb3464fa0c432e2cadda464c748f5ca81": "36",
  "macipk.a41c8058f57b501e8df1bdc68da7fd9485047230e907dcc13e039c7f640478a4": "37",
  "macipk.d9f8da791db8599e4a6220a3af6e98eb4e5452d4ab0ad366e929154a7b11748e": "38",
  "macipk.081b160caab29d8b1b71a12f6f20486e802692869db55e95c76e113f60aeafa9": "39",
  "macipk.4352de8c5dee5fcf8a1317f08baf18eb7ddca454550dd069a3d0536864e9b0ac": "40",
  "macipk.6e7097271c5b6c19ec768cfae22fb9563bf3e691fe7c00095a64d8fa3200182d": "41",
  "macipk.9dc5de288fcedaf82040a37a7bb64dd7f2ba14e35f88aa398f1c8be799460801": "42",
  "macipk.0963b2d42057e0cd3cf2f18ef1f8a9629fc5f1ba5e2d227d5e7711449f999fa9": "43",
  "macipk.ad41c7ab16817ae4d00ba4aeac3fabf797fff4670e60d7ed26f0e7b07dc8c613": "44",
  "macipk.72168dd4221c9afe1faa7ef1a5b6ad2ec2ee7301d415862ac59460e985e45308": "45",
  "macipk.69d6915c9e14010f44c9bda5a0dbbe8f6a0280df09f8ac5702a27756803fbca4": "46",
  "macipk.6d96af2468433d43c7ebd100314b241bb047f5f8e59cfbe7887c384c6102152e": "47",
  "macipk.b169f51876b014619b3b609864aaf8a8a83e68c3833f7de2fc181f0f1b476d18": "48",
  "macipk.c9c3f155944407f3dff6c41e9242dd4d168dbb6317a0a354c30a23213301fa9d": "49",
  "macipk.6229fc0d2f81911a7640d6c9fc1cda150f5b92ee707aec6d3bbcf7f1a1ff5c89": "50",
  "macipk.7d65b040eed8f4eb47897a6d8833218f4f357ff3e04fd9ff0b7abe28fd258700": "51",
  "macipk.2eda294028fc545a17d03d9c02614fab2c512e5f0e340b9e7a7886a584a47393": "52",
  "macipk.4422f482862b0c870dedd080c5c8e33960b755c4c8db498ade8801b9f119e793": "53",
  "macipk.be957b6bf6f9a0a1b73c44665a18ca5a619fb9d1ea0bc4561dbd571b61526e1b": "54",
  "macipk.1c22f006d10ac54405bf9543fdcdcf2bae33e0438aaaaa3a78a1a7d4d1d0a0a1": "55",
  "macipk.e1e881c4ad37fa7f4abac501509734e318c5bb5e861826d31df6cda671ddfd84": "56",
  "macipk.851469e3237cfd1306837e8f8261bfa15f86dc0d01eb3113776445995ac4d9a0": "57",
  "macipk.79389e3cdc41f7b92fa862f6d1fb0f992d3c695c89361fcd32f8895bdd1fc428": "58",
  "macipk.d20301ba8e65fb4ae65c801b8afc73a33f087ecc03223f4b0f135af734070e2c": "59",
  "macipk.763a14bdf1970a464be3ba5168dd298fea8acd38bf4986dc4c415e2ea5053fa6": "60",
  "macipk.1e09ff434c52434cdab6c22b449e1e98293cb6c36e7a4a75f4e0be7b31985c28": "61",
  "macipk.5832ecef523dbc211f83f740bb206b3e3d6d35bad5b97199ae85ea32ee194e2b": "62",
  "macipk.83314a2f8c820fd1b9973c01b23b82ef16a80003cca1051596fdcc3911b32a84": "63",
  "macipk.e2b0d5826abbf1747c278284e06a6caa8c34000ea36c9ea55792e0a0e6827c20": "64",
  "macipk.a6e0217ba606960fab91c26f8f9f4d413d65fe12291f6d310354c1d32f15cd1c": "65",
  "macipk.9b2b7ba0dc20c3b1da904bc2158369cb123c70685724f2225655ef3c2454312e": "66",
  "macipk.2543ee1e20f7d1c9bfbf7c2dfa805e4305497a4834634470998257470d6b5d16": "67",
  "macipk.9ad0b5ff0a8f6112ff29e1e0b064a346631fb8605a0662529c148302f152099a": "68",
  "macipk.a85ff40d73e959d902a15d20c51a3d878a15c974cda6a3e579ecac23a342540b": "69",
  "macipk.4df9fe8196b509d4fce737361514f65ac2d41836d6af3308d4bd598cecbe5b07": "70",
  "macipk.8a8516bf65e7343378183ef8909ddb046761277649975051baa1aa9282b206a7": "71",
  "macipk.2fb414d983d1fdca6ad3cdfc939d8c7c9493b9778f3c0e539ae4e5865f718a12": "72",
  "macipk.38606bb5d063320e84b497c6181d6e95edd264a97fa02c4d4892c1ecae3cbf83": "73",
  "macipk.20784b3642bcfce0efff072d0bb75582e5c36338c0422ebcc2eeac8a7063ea19": "74",
  "macipk.9ff9c6b8c363ecaa8dca59828f065e5618ba17e16b8ad8c462e80c4960bf100d": "75",
  "macipk.9b006e027ee0e1fb026018ccd53378ecc33966ad4a25c3f43c9f604bbf064d27": "76",
  "macipk.a0e68ecba3375a95e30e7c505a7496cadff34bd3ca66e50587f427d461a58929": "77",
  "macipk.9259ed1c35e0bbc818aae6b988bb252de86eca1b98ddf77b9a997f5952493090": "78",
  "macipk.0f813c5a316e769d6a1b0936fb021e18ee896ed1b8a4e5df8020fdc71e788027": "79",
  "macipk.0603c82112d373854a95368c7aa2862870693308681352caf03f556eaddc189a": "80",
  "macipk.1e0cce07f9b3d265c0990133310e9783049867270258b88f6857d09cf49b659f": "81",
  "macipk.4a669b7374b43e1d8a2edb01708a67c698e1e209de12143ee7f099322d7a0082": "82",
  "macipk.88f6a148265d55707da13ae2a9db44f048f7a85d16ddfafa065b25105884fb25": "83",
  "macipk.941f32123f77079eeae39bc3a3510a43050eec065341f83da152ea6d32f1f19e": "84",
  "macipk.11ec307700433121b96429ca995406ba3bffcbdbf520e2b695d32a554a843704": "85",
  "macipk.7c079e69a33a9adbe940e1e904ecb07705c47f40c6eef4f0e756c9e24dc45791": "86",
  "macipk.c36d30beff68ba0b6b160417fc3fa01451436d4ee3ed396314166fe6d77a3fa0": "87",
  "macipk.c274f616939dc57d8b15d0d7597540c2d095ee1972463756f9718d4105214081": "88",
  "macipk.c73ad98c6e16e08647aa4fd4a3db5e0d2725a230ea0df7a54f7b06e4c1b001a2": "89",
  "macipk.2ec4e854012dc4c4328acfd04e2dc359995a696b53cdb1b79d79c77ea8b66210": "90",
  "macipk.ef092b9f42a46a699bd958652468c023993cb89e411ba17d3e2db2290e314f13": "91",
  "macipk.dc0276b29e3fc4d89b963a4cf116f45472a08a38390a27eac2bc804cae8d938a": "92",
  "macipk.6646a1a1ff9ddd310340b7b764614c43b3514214db64a3e30c3d99d19fd53f18": "93",
  "macipk.21bb47c7a115346c64977b00e89c091fad9c98896b78a49a875eb572502ead0c": "94",
  "macipk.ef48a8364038a47e4c942dac72d8433e249966e3790b31a6ab273892b43716a5": "95",
  "macipk.9be2617c19d8e4313748f21b1487a6cabc8686e1eb8e3bf0be70a35ccf5d042c": "96",
  "macipk.907d49d1ccc65c3c7ece7160fd07122d0e9b134bcfbfe28739b7428b1b341eab": "97",
  "macipk.96b8506a81d008c81db1a0bdcbffe294e3cd6d72b31a7734ae793fba2a01931d": "98",
  "macipk.6cb0fc4f58d1cc367d01ede266ed2c4786adfed32d4739e623b98548d20e2484": "99",
  "macipk.b54eb9b3032867fd7d3406b9ecc711e13078447b4b9200c52db0803ab8e83492": "100"
};

//get state index by pk
export function getStateIndex(serializedPubKey: string): number {
  return stateIndexByPubKey[serializedPubKey];
}
